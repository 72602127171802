import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';
import Tooltip from './Tooltip';
import styled from 'styled-components';

const Display = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

function InvoiceItemSelect({ value, initialValue, type, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .invoiceItems()
        .get({ q, isActive: true, include: initialValue ? initialValue.id : undefined, size: 1000, type });

      return data;
    },
    [workspace.id, initialValue, api, type],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => (
        <Display>
          <Tooltip delay={250} maxWidth={'fit-content'} message={option.name}>
            <span>{option.name}</span>
          </Tooltip>
        </Display>
      )}
      {...props}
    />
  );
}

function FieldInvoiceItemSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <InvoiceItemSelect {...field} {...props} />
    </FieldControl>
  );
}

export default InvoiceItemSelect;
export { FieldInvoiceItemSelect };
