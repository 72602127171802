import { Button, Buttons, Drawer, FormMessage, Icon, InlineTooltip, TimeApprovalPopover, Tooltip } from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useFeatures, useSchemaForm, useTimeEntryTimer } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import { TimesheetProvider } from '../timesheets/TimesheetContext';
import BillingTab from './BillingTab';
import DeleteConfirmation from './DeleteConfirmation';
import GeneralTab from './GeneralTab';
import HistoryTab from './HistoryTab';
import AttachmentsTab from './AttachmentsTab';

const Statuses = styled.div`
  position: absolute;
  top: 0;
  right: 4rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 0.625rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.875rem;
  height: 1.875rem;
  padding: 0 1rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 999rem;
  box-shadow: 0 0.1875rem 0.5rem ${rgba(colors.black, 0.2)};

  .icon {
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    font-size: 0.625rem;
  }
`;

const InvoiceStatus = styled(Status)`
  color: ${colors.grey100};
`;

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 2.25rem;
  border-bottom: solid 1px ${colors.grey10};

  > * {
    margin-right: 2.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: flex-start;
  height: 1.75rem;
  margin-bottom: -1px;
  padding: 0;
  color: ${colors.black};
  font-size: 0.875rem;
  font-weight: ${({ isActive }) => (isActive ? weights.bold : weights.normal)};
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  border-bottom: solid 3px ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  border-radius: 0;
  cursor: pointer;

  &:hover {
    color: ${colors.black};
    background-color: transparent;
    border-color: ${({ isActive }) => (isActive ? colors.primary : colors.primary50)};
  }
`;

const TabIcon = styled(Icon)`
  margin-top: 0.125rem;
  margin-left: 0.25rem;
`;

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger};
  border-color: ${colors.danger};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.danger};
    border-color: ${colors.danger};
  }
`;

export default function EditTimeEntry({
  id,
  memberId,
  initialValues,
  member,
  onSaved,
  onDeleted,
  onClose,
  onSubmit,
  onConfirmDelete,
  onTimerChange,
}) {
  const isNew = !id;

  useDocumentTitle(isNew ? `New Time Entry` : 'Edit Time Entry');

  const api = useApi();
  const toast = useToast();
  const { notify } = useSubscription();
  const { workspace } = useWorkspace();
  if (!member) member = workspace.member;
  const { startStopTimer } = useTimeEntryTimer();
  const [entry, setEntry] = useState();
  const [project, setProject] = useState();
  const [role, setRole] = useState();
  const [task, setTask] = useState();
  const [files, setFiles] = useState();
  const [initialized, setInitialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedTab, setSelectedTab] = useState('general');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [drawerLoaded, setDrawerLoaded] = useState(false);

  const defaultValues = useMemo(() => {
    if (!entry) {
      return _.assign(
        {
          date: '',
          minutes: '',
          typeId: 'project_time',
          timeOffTypeId: '',
          projectId: '',
          projectRoleId: '',
          projectTaskId: '',
          notes: '',
          overrideBilling: false,
          isBillable: '',
          rate: '',
          statusId: 'not_submitted',
        },
        initialValues,
      );
    }
    return {
      date: entry.date,
      minutes: entry.minutes,
      typeId: entry.typeId,
      timeOffTypeId: entry.timeOffType?.id || '',
      projectId: entry.project?.id || '',
      projectRoleId: entry.role?.id || '',
      projectTaskId: entry.task?.id || '',
      notes: entry.notes || '',
      overrideBilling: typeof entry.isBillable === 'boolean',
      isBillable: typeof entry.isActuallyBillable === 'boolean' ? entry.isActuallyBillable.toString() : '',
      rate: entry.rate || (entry.project?.useRoles ? entry.role?.rate : entry.projectMember?.rate) || '',
      statusId: entry.statusId,
    };
  }, [entry, initialValues]);

  const schema = Yup.object().shape({
    date: Yup.string()
      .matches(/^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/)
      .nullable()
      .required()
      .label('Date')
      .test({
        message: `You cannot create a time entry with a date that is this far in the past.`,
        test: (value) => {
          if (
            // Check the permissions over the current/impersonated user.
            member?.permissions.manageTimeAndExpenses ||
            // Check permissions over the time entry's member.
            entry?.permissions?.manageMemberTime ||
            !workspace.lockTimeAndExpenses
          )
            return true;
          const age = moment.duration(moment().diff(moment(value), 'days'), 'days').asDays();
          return age <= workspace.lockTimeAndExpensesAfterDays;
        },
      })
      .test({
        message: `You cannot create a time entry with a date that is this far in the past.`,
        test: (value) => {
          if (
            member?.permissions.manageTimeAndExpenses ||
            entry?.permissions?.manageMemberTime ||
            !workspace.lockTimeAndExpensesAfterWeekEnds
          )
            return true;
          const age = moment.duration(moment().diff(moment(value).endOf('isoWeek'), 'days'), 'days').asDays();
          return age < workspace.lockTimeAndExpensesAfterWeekEndsDays;
        },
      })
      .test({
        message: `You cannot create a time entry with a date that is this far in the past.`,
        test: (value) => {
          if (
            member?.permissions.manageTimeAndExpenses ||
            entry?.permissions?.manageMemberTime ||
            !workspace.lockTimeAndExpensesAfterMonthEnds
          )
            return true;

          return moment(value)
            .startOf('month')
            .isAfter(
              moment()
                .subtract(moment().date() >= workspace.lockTimeAndExpensesAfterMonthEndsDays ? 1 : 2, 'months')
                .startOf('month'),
            );
        },
      }),
    minutes: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .when('$entry', (entry, schema) => (entry?.id ? schema.required() : schema))
      .when('$entry', (entry, schema) =>
        entry?.timerStartedAt
          ? schema
          : schema
              .min(1, 'A time entry must have at least 1 minute associated with it')
              .max(1440, 'A time entry cannot exceed 24 hours'),
      )
      .label('Hours'),
    typeId: Yup.string().required().label('Type'),
    timeOffTypeId: Yup.string().required().label('Time Off Type'),
    projectId: Yup.string().required().label('Project'),
    projectRoleId: Yup.string().required().label('Role'),
    projectTaskId: Yup.string()
      .label('Task')
      .when('$project', (project, schema) =>
        project.requireTimeEntryTask
          ? schema.required('This project requires time entries to be associated with a task.')
          : schema,
      ),
    notes: Yup.string()
      .trim()
      .label('Notes')
      .max(5000)
      .when(['$project', '$task'], (project, task, schema) => {
        return project?.requireNotes || task?.requireNotes
          ? schema.required(
              project?.requireNotes
                ? 'This project requires time entries to have notes.'
                : task?.requireNotes
                  ? 'This task requires time entries to have notes.'
                  : undefined,
            )
          : schema;
      }),

    overrideBilling: Yup.boolean().label('Override Billing'),
    isBillable: Yup.boolean()
      .nullable()
      .transform((value) => (_.isBoolean(value) ? value : null))
      .when('overrideBilling', (overrideBilling, schema) => (overrideBilling ? schema.required() : schema))
      .label('Is Billable'),
    rate: Yup.number()
      .min(0)
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .when(['overrideBilling', 'isBillable'], (overrideBilling, isBillable, schema) =>
        overrideBilling && isBillable ? schema.required() : schema,
      )
      .label('Rate'),
    statusId: Yup.string()
      .oneOf(['not_submitted', 'pending_approval', 'rejected', 'approved'])
      .required()
      .label('Approval Status'),
  });

  const isOwner = isNew ? member.id === workspace.member.id : entry?.member.id === workspace.member.id;

  const hasAdminRights = useCallback(
    (values) =>
      !isNew &&
      // The member can manage the member's time and expenses
      (entry?.permissions?.manageMemberTime ||
        // The time entry is project time and the member has administrative rights on the selected project
        (values.typeId === 'project_time' && project?.permissions.manageTimeAndExpenses) ||
        // The time entry is time off and the member is the time off approver of the time entry's member
        (values.typeId === 'time_off' &&
          ((entry?.member.timeOffApprovalMode === 'member' &&
            entry?.member.timeOffApproverId === workspace.member.id) ||
            (entry?.member.timeOffApprovalMode === 'manager' && entry?.member.managerId === workspace.member.id)))),
    [
      isNew,
      entry?.permissions?.manageMemberTime,
      project,
      entry?.member.timeOffApprovalMode,
      entry?.member.timeOffApproverId,
      entry?.member.managerId,
      workspace.member.id,
    ],
  );

  const billingEnabled = useCallback(
    (values) =>
      !isNew &&
      hasAdminRights(values) &&
      values.typeId === 'project_time' &&
      project?.isBillable &&
      project?.permissions.viewRates,
    [hasAdminRights, isNew, project],
  );

  const features = useFeatures();

  const schemaConditionals = {
    timeOffTypeId: ({ typeId }) => typeId === 'time_off',
    projectId: ({ typeId }) => typeId === 'project_time',
    projectRoleId: ({ typeId, project }) => typeId === 'project_time' && !!project?.useRoles,
    projectTaskId: ({ typeId, project }) => typeId === 'project_time' && project?.taskCount > 0,
    requireNotes: ({ task, project }) => {
      return task?.requireNotes || project?.requireNotes;
    },
    overrideBilling: (values) => billingEnabled(values),
    isBillable: (values) => billingEnabled(values),
    rate: ({ isBillable, ...values }) => billingEnabled(values) && isBillable === 'true',
    statusRights: (values) => {
      if (isNew) return null;
      if (
        entry?.typeId === 'project' &&
        !entry?.permissions?.manageMemberTime &&
        _.isEmpty(workspace.member.securityRole.manageProjectTimeAndExpenses)
      )
        return null;
      if (entry?.typeId === 'time_off' && !entry?.permissions?.manageMemberTime && !workspace.member.isTimeOffApprover)
        return null;
      if (hasAdminRights(values)) return 'write';
      return 'read';
    },
    member: () => {
      if (isOwner) return null;
      if (isNew) return member.name;
      return entry?.member.name;
    },
    disableTimer: ({ entry }) => {
      if (entry?.timerStartedAt) return;
      if (!isOwner) return 'You can only start timers on your own time entries.';
      if (!entry) return;
      if (entry.statusId === 'pending_approval' || (entry.statusId === 'approved' && entry.statusModeId === 'manual'))
        return 'You can only start timers on time entries that are either "Not Submitted", "Rejected" or "Automatically Approved".';
      if (entry.invoiceId) return 'This time entry is associated with an invoice and its timer cannot be started.';
      if (entry.clientApprovalId)
        return 'This time entry is associated with a client approval and its timer cannot be started.';
      if (features.timesheets && entry.timesheetId)
        return 'This time entry is associated with a timesheet and its timer cannot be started.';
    },
    typeRights: () => (isNew || isOwner || entry?.permissions?.manageMemberTime ? 'write' : 'read'),
    projectRights: () => (isNew || isOwner || entry?.permissions?.manageMemberTime ? 'write' : 'read'),
    invoiced: () => !isNew && !!entry?.invoiceId,
    clientApproval: () => !isNew && !!entry?.clientApprovalId,

    typeSelectDisabledTooltip: () => {
      if (schemaConditionals.invoiced())
        return 'This time entry is associated with an invoice and its type cannot be changed.';

      if (schemaConditionals.clientApproval())
        return 'This time entry is associated with a client approval and its type cannot be changed.';

      if (schemaConditionals.typeRights() !== 'write')
        return `Insufficient permissions to edit this time entry's type.`;
    },

    projectSelectDisabledTooltip: () => {
      if (schemaConditionals.invoiced())
        return 'This time entry is associated with an invoice and its project cannot be changed.';

      if (schemaConditionals.clientApproval())
        return 'This time entry is associated with a client approval and its project cannot be changed.';

      if (schemaConditionals.projectRights() !== 'write')
        return `Insufficient permissions to edit this time entry's project.`;
    },
  };

  const form = useSchemaForm({
    initialValues: defaultValues,
    schema,
    schemaConditionals,
    schemaContext: { entry, project, task },
  });
  const { values, errors, reset, isDirty } = form;
  const formMinutes = values.minutes;
  const formProjectId = values.projectId;
  const formProjectRoleId = values.projectRoleId;
  const formProjectTaskId = values.projectTaskId;
  const formDate = values.date;
  const disableTimerTooltip = !formMinutes && schemaConditionals.disableTimer({ entry });

  const saveLabel = useMemo(
    () => (!!entry?.id || formMinutes > 0 ? 'Save & Close' : 'Start Timer'),
    [entry, formMinutes],
  );

  const tabs = useMemo(() => {
    const value = ['general'];
    if (billingEnabled(form.values)) {
      value.push('billing');
    }
    if (files?.length > 0) {
      value.push('attachments');
    }
    if (entry?.history?.length > 0) {
      value.push('history');
    }
    return value;
  }, [billingEnabled, entry, form.values, files]);

  const tabErrors = useMemo(() => {
    const errorFields = _.keys(errors);
    const generalFields = [
      'date',
      'minutes',
      'typeId',
      'timeOffTypeId',
      'projectId',
      'projectRoleId',
      'projectTaskId',
      'notes',
    ];
    const billingFields = ['overrideBilling', 'isBillable', 'rate'];
    const value = [];
    if (_.intersection(errorFields, generalFields).length > 0) {
      value.push('general');
    }
    if (_.intersection(errorFields, billingFields).length > 0) {
      value.push('billing');
    }
    return value;
  }, [errors]);

  const fetchEntry = useCallback(async () => {
    if (!id) {
      setEntry();
      setInitialized(true);
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries(id).get();
      setEntry(data);
    } catch (error) {
      setEntry();
    } finally {
      setInitialized(true);
    }
  }, [api, id, workspace]);

  const fetchProject = useCallback(async () => {
    if (!formProjectId) {
      setProject();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().projects(formProjectId).get();
      setProject(data);
    } catch (error) {
      setProject();
    }
  }, [api, formProjectId, workspace]);

  const fetchRole = useCallback(async () => {
    if (!formProjectRoleId) {
      setRole();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().roles(formProjectRoleId).get();
      setRole(data);
    } catch (error) {
      setRole();
    }
  }, [api, formProjectRoleId, workspace]);

  const fetchTask = useCallback(async () => {
    if (!formProjectTaskId) {
      setTask();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().tasks(formProjectTaskId).get();
      setTask(data);
    } catch (error) {
      setTask();
    }
  }, [api, formProjectTaskId, workspace]);

  // Fetch data on load/when dependencies change
  useEffect(() => {
    fetchEntry();
  }, [fetchEntry]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const fetchFiles = useCallback(async () => {
    if (!formDate || !formProjectId || !memberId || !id) {
      setFiles();
      return;
    }
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .timeEntries(id)
        .getWeekFiles({
          date: moment(formDate).startOf('isoWeek').format(dateFormats.exportDate),
          memberId: memberId,
          projectId: formProjectId,
        });

      setFiles(data);
    } catch (error) {
      setFiles(null);
    }
  }, [api, workspace, formDate, formProjectId, memberId, id]);

  useEffect(() => {
    if (!features.timeAttachments) return;
    fetchFiles();
  }, [fetchFiles, features.timeAttachments]);

  // Reset the form with new defaults after initialization
  useEffect(() => {
    if (initialized) {
      reset();
    }
  }, [initialized, reset]);

  const dirtyCheck = useDirtyCheck(() => isDirty);

  async function handleDelete(callback) {
    if (_.isFunction(onDeleted)) {
      await onDeleted(entry);
    }
    if (_.isFunction(callback)) {
      callback();
    }
    notify(useSubscription.keys.refresh_time_approval_count);
  }

  async function handlePlayPause(entry) {
    const newEntry = await startStopTimer(entry);
    if (newEntry) {
      setEntry(newEntry);
      reset(['minutes']);
    }
    if (_.isFunction(onTimerChange)) onTimerChange(newEntry);
  }

  async function handleSubmit(values, closeDrawer) {
    if (isSubmitting) {
      return;
    }

    if (values.typeId === 'project_time') {
      values.timeOffTypeId = null;
    } else if (values.typeId === 'time_off') {
      values.projectId = null;
      values.projectRoleId = null;
      values.projectTaskId = null;

      if (billingEnabled(values)) {
        // The values can be set to null when the member is a time admin since the API schema allows it.
        values.isBillable = null;
        values.rate = null;
      } else {
        // But for non-admins, the values can't be set.
        // The API handles this scenario by resetting the values for time off.
        delete values.isBillable;
        delete values.rate;
      }
    }

    if (values.timeOffTypeId === undefined) {
      values.timeOffTypeId = null;
    }
    if (values.projectId === undefined) {
      values.projectId = null;
    }
    if (values.projectRoleId === undefined) {
      values.projectRoleId = null;
    }
    if (values.projectTaskId === undefined) {
      values.projectTaskId = null;
    }

    if (values.overrideBilling === false) {
      values.isBillable = null;
      values.rate = null;
    }
    if (values.isBillable === false) {
      values.rate = null;
    }
    delete values.overrideBilling;

    if (memberId) {
      values.memberId = memberId;
    }

    if (form.conditionals.statusRights !== 'write') {
      delete values.statusId;
    } else if (values.statusId !== entry.statusId) {
      values.statusModeId = 'manual';
    }

    const body = emptyStringToNull(values);

    setErrorMessage(null);
    setIsSubmitting(true);

    try {
      const { data } = onSubmit
        ? await onSubmit(body)
        : await api.www.workspaces(workspace.id).timeEntries(entry?.id).upsert(body);

      setEntry(data);
      reset();

      if (_.isFunction(onSaved)) {
        onSaved(data);
      }

      if (_.isFunction(closeDrawer)) {
        closeDrawer();
      }

      toast.success('Time entry has been saved.');
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch ({ message }) {
      setErrorMessage(message);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleCancelClose = (closeDrawer) => (event) => {
    if (_.isFunction(closeDrawer)) {
      closeDrawer(event);
    }
  };

  const actions = (closeDrawer) => (
    <>
      {entry && (
        <DeleteButton isOutline onClick={() => setConfirmDelete(true)}>
          Delete
        </DeleteButton>
      )}
      <Buttons align="right">
        <CancelButton onClick={() => dirtyCheck(handleCancelClose(closeDrawer))}>Close</CancelButton>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={!!disableTimerTooltip}
          style={{ position: 'relative' }}
          onClick={form.handleSubmit((values) => handleSubmit(values, closeDrawer))}>
          {saveLabel}
          {disableTimerTooltip && <InlineTooltip message={disableTimerTooltip} />}
        </Button>
      </Buttons>
    </>
  );

  const rejectedHistoryItem = useMemo(() => _.find(entry?.history, { statusId: 'rejected' }), [entry]);
  const statusMessage = !entry?.status
    ? 'New time entry not yet submitted for approval.'
    : {
        approved: `Time entry has been ${entry.statusModeId === `auto` ? 'automatically' : `manually`} approved.`,
        rejected: `Time entry has been rejected.`,
        not_submitted: 'Time entry has not been submitted for approval.',
        pending_approval: 'Time entry has been submitted and is pending approval.',
      }[entry.status?.id];

  const clientStatusMessage = {
    approved: `Time entry has been approved by the client.`,
    rejected: `Time entry has been rejected by the client.`,
  }[entry?.clientStatus?.id];

  return (
    <TimesheetProvider memberId={entry?.memberId ?? memberId}>
      <Drawer
        title={isNew ? 'New Entry' : 'Edit Entry'}
        byline="Time"
        actions={initialized ? actions : null}
        onOpen={() => setDrawerLoaded(true)}
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={onClose}
        isOpen>
        {(closeDrawer) =>
          initialized ? (
            <form onSubmit={form.handleSubmit((values) => handleSubmit(values, closeDrawer))} noValidate>
              <Statuses>
                {entry?.invoice?.number && (
                  <Tooltip
                    message={`Time entry is associated with ${
                      { open: 'published', draft: 'draft' }[entry?.invoice?.statusId]
                    } invoice #${entry?.invoice?.number}.`}>
                    <InvoiceStatus>
                      <Icon icon="file-invoice" color={colors.grey40} />
                      Invoiced
                    </InvoiceStatus>
                  </Tooltip>
                )}

                {features.clientApprovals && entry?.clientStatus?.id && (
                  <Tooltip message={clientStatusMessage}>
                    <Status status={entry.clientStatus.id}>
                      <Icon icon="circle" />
                      {entry.clientStatus.name}
                    </Status>
                  </Tooltip>
                )}

                {entry?.status?.id && (
                  <>
                    {entry.status.id === 'pending_approval' && entry.typeId === 'project_time' ? (
                      <TimeApprovalPopover timeEntryId={entry.id} placement="left">
                        <Status status={entry.status.id}>
                          <Icon icon="circle" />
                          {entry.status.name}
                        </Status>
                      </TimeApprovalPopover>
                    ) : (
                      <Tooltip message={statusMessage}>
                        <Status status={entry.status.id}>
                          <Icon icon="circle" />
                          {entry.status.name}
                        </Status>
                      </Tooltip>
                    )}
                  </>
                )}
              </Statuses>

              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {tabs.length > 1 && (
                <Tabs>
                  {_.includes(tabs, 'general') && (
                    <Tab
                      isActive={selectedTab === 'general'}
                      data-testid="general_tab"
                      onClick={() => setSelectedTab('general')}>
                      General
                      {_.includes(tabErrors, 'general') && <TabIcon icon="exclamation-circle" color={colors.danger} />}
                    </Tab>
                  )}
                  {_.includes(tabs, 'billing') && (
                    <Tab
                      isActive={selectedTab === 'billing'}
                      data-testid="billing_tab"
                      onClick={() => setSelectedTab('billing')}>
                      Billing
                      {_.includes(tabErrors, 'billing') && <TabIcon icon="exclamation-circle" color={colors.danger} />}
                    </Tab>
                  )}
                  {_.includes(tabs, 'attachments') && (
                    <Tab isActive={selectedTab === 'attachments'} onClick={() => setSelectedTab('attachments')}>
                      Attachments
                      {_.includes(tabErrors, 'attachments') && (
                        <TabIcon icon="exclamation-circle" color={colors.danger} />
                      )}
                    </Tab>
                  )}
                  {_.includes(tabs, 'history') && (
                    <Tab isActive={selectedTab === 'history'} onClick={() => setSelectedTab('history')}>
                      History
                      {_.includes(tabErrors, 'history') && <TabIcon icon="exclamation-circle" color={colors.danger} />}
                    </Tab>
                  )}
                </Tabs>
              )}
              {selectedTab === 'general' && (
                <GeneralTab
                  form={form}
                  entry={entry}
                  project={project}
                  role={role}
                  task={task}
                  memberId={memberId}
                  member={member}
                  onPlayPause={handlePlayPause}
                  isNew={isNew}
                  rejectedHistoryItem={rejectedHistoryItem}
                  drawerLoaded={drawerLoaded}
                />
              )}
              {selectedTab === 'billing' && (
                <BillingTab form={form} entry={entry} project={project} role={role} task={task} />
              )}
              {selectedTab === 'attachments' && <AttachmentsTab files={files} />}
              {selectedTab === 'history' && <HistoryTab history={entry?.history} />}
              {confirmDelete && (
                <DeleteConfirmation
                  id={id}
                  onConfirmDelete={onConfirmDelete}
                  onClose={() => setConfirmDelete(false)}
                  onDelete={() => handleDelete(closeDrawer)}
                />
              )}
            </form>
          ) : (
            <PageLoader />
          )
        }
      </Drawer>
    </TimesheetProvider>
  );
}
