import {
  ActionButton,
  Buttons,
  CancelButton,
  Confirmation,
  DeleteButton,
  Drawer,
  FormMessage,
  Icon,
  Inline,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  TooltipButton,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useAuth, useDirtyCheck, useDocumentTitle, useForm, useIsMounted } from '~/hooks';
import _ from 'lodash';
import projectStatuses from '~/lookups/project-statuses';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import settings from '~/settings.js';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import { slugValidator } from '~/utils/validators';
import * as Yup from 'yup';
import ProjectDeleteConfirmation from '../ProjectDeleteConfirmation';
import ProjectForm from './ProjectForm';
import RecognizeRemainingRevenueConfirmation from './RecognizeRemainingRevenueConfirmation';
import AccountingTab from './accounting/AccountingTab';
import BudgetTab from './budget/BudgetTab';
import RolesTab from './roles/RolesTab';
import TeamTab from './team/TeamTab';

const ProjectDrawerContext = React.createContext({ forms: [] });

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const initialState = { project: null, isReady: false, isSlackConnected: false };
const handlers = {
  ready: ({ project }) => ({ isReady: true, project }),
  slackConnected: (isConnected) => ({ isSlackConnected: isConnected }),
};

function ProjectDrawer({ onSaved, onClose, onDeleted, ...props }) {
  useDocumentTitle('Edit Project');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const [{ isReady, project, isSlackConnected }, actions] = useActions(handlers, initialState);
  const [tabIndex, setTabIndex] = useState(props.selectedTabIndex || settings.defaultProjectDrawerTabIndex);
  const [drawerLoaded, setDrawerLoaded] = useState(false);

  const { clientKey, projectKey, projectTab } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [forms, setForms] = useState([]);

  const auth = useAuth();
  const confirmation = useConfirmation();

  const drawerRef = useRef();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty || forms.length > 0);

  const isMounted = useIsMounted();

  const projectId = props.projectId ?? project?.id;

  const fetchData = useCallback(async () => {
    try {
      // If the project is set, use its id instead of the keys.
      // This is to prevent using a client key that changed from within the form (if the client was changed).
      const { data: project } = projectId
        ? await api.www.workspaces(workspace.id).projects(projectId).graph()
        : await api.www.workspaces(workspace.id).clients(clientKey).projects(projectKey).graph();

      actions.ready({ project });
      return project;
    } catch (error) {
      actions.ready({ project: null });
    }
  }, [actions, workspace.id, clientKey, projectKey, projectId, api]);

  useEffect(() => {
    if (project) return;
    fetchData();
  }, [fetchData, project]);

  useEffect(() => {
    (async () => {
      const { data: isConnected } = await api.www.workspaces(workspace.id).integrations().slack.getConnected();
      actions.slackConnected(isConnected);
    })();
  }, [actions, api, workspace.id]);

  const integrations = useIntegrations();
  const [QBOProjectsEnabled, setQBOProjectsEnabled] = useState(false);
  useEffect(() => {
    if (!integrations.qbo) return;
    if (!auth.workspace.manage) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).qbo.preferences.projectsEnabled();
        if (!isMounted.current) return;
        setQBOProjectsEnabled(data);
      } catch {
        // Ignore
      }
    })();
  }, [workspace.id, integrations.qbo, api, isMounted, auth.workspace.manage]);

  function handleClose() {
    if (onClose) {
      onClose(projectTab);
    }
  }

  function handleDelete() {
    confirmation.prompt((resolve) => (
      <ProjectDeleteConfirmation
        project={project}
        onClose={resolve}
        onDelete={() => {
          if (onDeleted) onDeleted(project);
          resolve(true);
        }}
      />
    ));
  }

  function handlePathChange(data) {
    // TODO: need to find a more graceful way to handle this
    // Possibly handle in the parent level and ideally without string manipulation
    if (data.key !== projectKey || (data?.client && data.client.key !== clientKey)) {
      let url;
      if (location.pathname.includes('/projects/edit')) {
        url = location.pathname.replace(
          `/projects/edit/${clientKey}/${projectKey}`,
          `/projects/edit/${data.client.key}/${data.key}`,
        );
      } else {
        url = location.pathname.replace(
          `/projects/${clientKey}/${projectKey}`,
          `/projects/${data.client.key}/${data.key}`,
        );
      }

      history.replace(url);
    }
  }

  if (!isReady) return null;
  if (!project) return <ErrorPage.NotFound publicSite={false} />;

  if (!project.permissions.edit) {
    const url = location.pathname.includes('/projects/edit')
      ? `/app/${workspace.key}/projects`
      : location.pathname.replace('/edit', '');

    return <Redirect to={url} />;
  }

  const initialValues = mergeValues(
    {
      id: undefined,

      // Overview tab
      billingTypeId: 'tm',
      client: null,
      code: '',
      completedOn: null,
      currency: null,
      description: '',
      enableClientApprovals: false,
      end: null,
      isBillable: true,
      isExpensesApprovalRequired: true,
      isTimeApprovalRequired: true,
      lockTimeAndExpenses: true,
      name: '',
      key: '',
      revenueRecognitionMethod: 'invoiced',
      revenueAttributionMethod: 'hours',
      opportunity: null,
      poNumber: '',
      practice: null,
      projectType: '',
      salesRepresentative: project.salesRepresentative ?? null,
      start: null,
      statusId: 'not_started',
      slackChannelId: '',
      cloudFolderUrl: '',
      autoAssignMembers: false,
      assignmentNotifications: true,
      tags: [],
      isProductive: false,
      useHealthReports: true,

      // Time Tracking Rules
      capMaxMemberHoursPerDay: false,
      maxMemberHoursPerDay: 8,
      capMaxMemberHoursPerWeek: false,
      maxMemberHoursPerWeek: 40,
      forbidTimeOnHolidays: true,
      forbidTimeOnWeekends: true,
      requireNotes: false,
      requireTimeEntryTask: false,
      trackTimeToAssignedRoles: false,

      // Invoice details
      useClientInvoiceDetails: true,
      email: '',
      emails: [],
      ccEmail: '',
      ccEmails: [],
      paymentTermsId: '',
      invoiceTaxableItems: [],
      invoiceTaxRate: null,
      streetAddress: '',
      invoiceNotes: '',

      // QuickBooks Online
      qboProjectId: '',

      // Team tab
      members: [],

      // Roles tab
      useRoles: false,
      roles: [],

      // Accounting Tab
      projectExpenses: [],
      invoiceMilestones: [],
      otherItems: [],
      revenueRecognitionEntries: [],

      // Budget Tab
      useBudget: false,
      budgetMode: '',
      estimatedBillableHours: '',
      estimatedNonBillableHours: '',
      estimatedServiceRevenue: '',
      budgetExpensesBillableAmount: '',
      budgetExpensesNonBillableAmount: '',
      budgetExpenses: [],
      budgetOtherItemsFee: '',
      budgetOtherItems: [],
      capRevenue: false,
      capHours: false,
      capExpenses: false,
      budgetServicesGrossMargin: '',

      // Monthly Budget Tab
      useMonthlyBudget: false,
      monthlyBudgetMode: '',
      estimatedMonthlyBillableHours: '',
      estimatedMonthlyNonBillableHours: '',
      estimatedMonthlyServiceRevenue: '',
      monthlyBudgetExpensesBillableAmount: '',
      monthlyBudgetExpensesNonBillableAmount: '',
      monthlyBudgetExpenses: [],
      monthlyBudgetOtherItemsFee: '',
      monthlyBudgetOtherItems: [],
      capMonthlyHours: false,

      // Budget Alerts
      budgetHoursAlert: false,
      budgetHoursAlertThreshold: 0.8,
      budgetRevenueAlert: false,
      budgetRevenueAlertThreshold: 0.8,
    },
    {
      ...project,
      email: project.emails ? project.emails.join(', ') : '',
      ccEmail: project.ccEmails ? project.ccEmails.join(', ') : '',
      budgetServicesGrossMargin: _.isNumber(project.budgetServicesGrossMargin)
        ? _.round(project.budgetServicesGrossMargin * 100, 2)
        : '',
    },
  );

  if (project.useClientInvoiceDetails) {
    initialValues.emails = project.client.emails;
    initialValues.email = project.client.emails.join(', ');
    initialValues.ccEmails = project.client.ccEmails;
    initialValues.ccEmail = project.client.ccEmails.join(', ');
    initialValues.streetAddress = project.client.streetAddress ?? '';
    if (project.client.useWorkspaceInvoiceDetails) {
      initialValues.paymentTermsId = workspace.paymentTermsId ?? '';
      initialValues.invoiceTaxableItems = workspace.invoiceTaxableItems;
      initialValues.invoiceTaxRate = workspace.invoiceTaxRate ?? '';
      initialValues.invoiceNotes = workspace.invoiceNotes ?? '';
    } else {
      initialValues.paymentTermsId = project.client.paymentTermsId ?? '';
      initialValues.invoiceTaxableItems = project.client.invoiceTaxableItems;
      initialValues.invoiceTaxRate = project.client.invoiceTaxRate ?? '';
      initialValues.invoiceNotes = project.client.invoiceNotes ?? '';
    }
  }

  const schema = Yup.object().shape({
    billingTypeId: Yup.string().label('Billing Type').required(),
    client: Yup.mixed().label('Client').required(),
    cloudFolderUrl: Yup.string()
      .label('Cloud Folder')
      .url('Please include http:// or https:// protocols in your URL')
      .max(1024),
    code: Yup.string().label('Project Code').max(255),
    completedOn: Yup.date()
      .label('Completed On')
      .nullable()
      .when('statusId', (statusId, schema) => (statusId === 'completed' ? schema.required() : schema)),
    currency: Yup.string().label('Currency').required(),
    description: Yup.string().label('Notes').max(5000),
    end: Yup.date()
      .label('End Date')
      .nullable()
      .when('start', (start, schema) =>
        start ? schema.min(Yup.ref('start'), 'End Date must be after Start Date') : schema,
      ),
    name: Yup.string().label('Project Name').max(255).required(),
    key: Yup.string()
      .label('Project URL ID')
      .matches(slugValidator.expression, { message: slugValidator.message })
      .max(255)
      .required(),
    poNumber: Yup.string().label('PO Number').max(255),
    projectTypeId: Yup.string().label('Project Type'),
    salesRepresentative: Yup.object().label('Sales Representative').nullable(),
    start: Yup.date().label('Start Date').nullable(),
    statusId: Yup.string().label('Status').required(),

    // Invoice Details
    emails: Yup.array().of(Yup.string().email().max(255).required()).label('To Email Address').ensure(),
    ccEmails: Yup.array().of(Yup.string().email().max(255).required()).label('Cc Email Address').ensure(),
    paymentTermsId: Yup.string().label('Payment Terms').required(),
    streetAddress: Yup.string().label('Invoice Street Address').max(5000),
    invoiceNotes: Yup.string().label('Invoice Note').max(5000),

    // Team tab
    members: Yup.array().test({
      message: 'At least one active Project Admin should be set.',
      test: (members) => members.some((member) => member.typeId === 'administrator' && member.isActive === true),
    }),

    // Budget tab
    estimatedBillableHours: Yup.number().label('Billable Hours Budget').min(0).max(999999.99).nullable(),
    estimatedNonBillableHours: Yup.number().label('Non-Billable Hours Budget').min(0).max(999999.99).nullable(),
    estimatedServiceRevenue: Yup.number().label('Services Revenue Budget').min(0).max(99999999999).nullable(),
    budgetExpensesBillableAmount: Yup.number().label('Billable Expenses Budget').min(0).max(99999999999).nullable(),
    budgetExpensesNonBillableAmount: Yup.number()
      .label('Non-Billable Expenses Budget')
      .min(0)
      .max(99999999999)
      .nullable(),
    budgetOtherItemsFee: Yup.number().label('Other Items Budget').min(-99999999999).max(99999999999).nullable(),

    // Monthly Budget tab
    estimatedMonthlyBillableHours: Yup.number().label('Monthly Billable Hours Budget').min(0).max(999999.99).nullable(),
    estimatedMonthlyNonBillableHours: Yup.number()
      .label('Monthly Non-Billable Hours Budget')
      .min(0)
      .max(999999.99)
      .nullable(),
    estimatedMonthlyServiceRevenue: Yup.number()
      .label('Monthly Services Revenue Budget')
      .min(0)
      .max(99999999999)
      .nullable(),
    monthlyBudgetExpensesBillableAmount: Yup.number()
      .label('Monthly Billable Expenses Budget')
      .min(0)
      .max(99999999999)
      .nullable(),
    monthlyBudgetExpensesNonBillableAmount: Yup.number()
      .label('Monthly Non-Billable Expenses Budget')
      .min(0)
      .max(99999999999)
      .nullable(),
    monthlyBudgetOtherItemsFee: Yup.number()
      .label('Monthly Other Items Budget')
      .min(-99999999999)
      .max(99999999999)
      .nullable(),
  });

  const tabErrors = {
    overview: [
      'billingTypeId',
      'client',
      'code',
      'completedOn',
      'description',
      'end',
      'isBillable',
      'isExpensesApprovalRequired',
      'isTimeApprovalRequired',
      'name',
      'poNumber',
      'projectTypeId',
      'start',
      'statusId',
    ],
    team: ['members'],
  };

  return (
    <ProjectDrawerContext.Provider value={{ forms, setForms }}>
      <Drawer
        isOpen
        title="Edit Project"
        byline={project.name}
        ref={drawerRef}
        onOpen={() => setDrawerLoaded(true)}
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}
        width="70rem">
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values) {
            // When the project is set to complete, prompt the user to recognize the remaining revenue
            if (
              values.revenueRecognitionMethod === revenueRecognitionMethods.manual.id &&
              initialValues.statusId !== projectStatuses.completed.id &&
              values.statusId === projectStatuses.completed.id
            ) {
              const revenueRecognizedToDate = _.round(_.sumBy(values.revenueRecognitionEntries, 'amount'), 2);
              const billingScheduleRevenue = _.round(_.sumBy(values.invoiceMilestones, 'fee'), 2);
              const remainingRevenue = _.round(billingScheduleRevenue - revenueRecognizedToDate, 2);

              if (revenueRecognizedToDate < billingScheduleRevenue) {
                const recognizeRemainingRevenue = await confirmation.prompt((resolve) => (
                  <RecognizeRemainingRevenueConfirmation
                    currency={values.currency}
                    billingScheduleRevene={billingScheduleRevenue}
                    revenueRecognizedToDate={revenueRecognizedToDate}
                    remainingRevenue={remainingRevenue}
                    onConfirm={resolve}
                    onClose={() => resolve(false)}
                  />
                ));

                if (!recognizeRemainingRevenue) return;

                if (recognizeRemainingRevenue === 'yes') {
                  values = {
                    ...values,
                    revenueRecognitionEntries: [
                      ...values.revenueRecognitionEntries,
                      {
                        id: _.uniqueId('rre_'),
                        date: values.completedOn,
                        amount: remainingRevenue,
                        notes: 'Entry added automatically to recognize the remaining revenue on project completion.',
                      },
                    ],
                  };
                }
              }
            }

            if (
              !values.useRoles &&
              values.members.some(
                (member) =>
                  member.hasTimeEntries &&
                  project.members.find(
                    (pm) => pm.id === member.id && (member.rate !== pm.rate || member.isBillable !== pm.isBillable),
                  ),
              )
            ) {
              const confirm = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  You have changed the bill rate on one or more members that already have time tracked. If you want
                  those existing time entries to use the new rate(s), click OK below. If you do not want the rate to
                  change on the existing time entries, click Cancel.
                </Confirmation>
              ));
              if (!confirm) {
                return;
              }
            }

            if (
              values.useRoles &&
              values.roles.some(
                (projectRole) =>
                  projectRole.hasTimeEntries &&
                  project.roles.find(
                    (pr) =>
                      pr.id === projectRole.id &&
                      (pr.rate !== projectRole.rate || pr.isBillable !== projectRole.isBillable),
                  ),
              )
            ) {
              const confirm = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  You have changed the bill rate on one or more project roles that already have existing time entries.
                  If you want those existing time entries to use the new rate(s), click OK below. If you do not want the
                  rate to change on the existing time entries, click Cancel, disable the old roles/rates, create new
                  roles/rates, and assign project team members to the appropriate roles.
                </Confirmation>
              ));
              if (!confirm) {
                return;
              }
            }

            try {
              form.submit(formRef.current?.status?.action);

              const body = emptyStringToNull({
                ..._.omit(values, [
                  'id',
                  'client',
                  'email',
                  'ccEmail',
                  'invoiceTaxRate',
                  'opportunity',
                  'practice',
                  'projectType',
                  'tags',
                  'salesRepresentative',
                ]),
                clientId: values.client.id,
                opportunityId: values.opportunity?.id ?? null,
                practiceId: values.practice?.id ?? null,
                invoiceTaxRateId: values.invoiceTaxRate?.id ?? null,
                projectTypeId: values.projectType?.id ?? null,
                salesRepresentativeId: values.salesRepresentative?.id ?? null,
                members: values.members.map((pm) => {
                  const columns = [
                    'id',
                    'estimatedBillableHours',
                    'estimatedNonBillableHours',
                    'estimatedMonthlyBillableHours',
                    'estimatedMonthlyNonBillableHours',
                    'isBillable',
                    'isActive',
                    'memberId',
                    'rate',
                    'roles',
                    'typeId',
                  ];

                  if (auth.workspace.manage) {
                    columns.push('overrideCostRate', 'costCurrency', 'costPerHour', 'overheadCostPerHour');
                  }

                  return {
                    ..._.pick(pm, columns),
                    roles: pm.roles.map((pmr) => _.pick(pmr, 'id', 'projectMemberId', 'projectRoleId')),
                  };
                }),
                roles: values.roles.map((pr) =>
                  _.pick(
                    pr,
                    'id',
                    'estimatedBillableHours',
                    'estimatedNonBillableHours',
                    'estimatedMonthlyBillableHours',
                    'estimatedMonthlyNonBillableHours',
                    'isBillable',
                    'isActive',
                    'name',
                    'rate',
                    'disciplineId',
                    'practiceId',
                    'locationId',
                  ),
                ),
                budgetExpenses: values.budgetExpenses.map((be) =>
                  _.pick(be, 'id', 'nonBillableAmount', 'billableAmount', 'expenseCategoryId', 'notes'),
                ),
                monthlyBudgetExpenses: values.monthlyBudgetExpenses.map((be) =>
                  _.pick(be, 'id', 'nonBillableAmount', 'billableAmount', 'expenseCategoryId', 'notes'),
                ),
                otherItems: values.otherItems.map((oi) =>
                  _.pick(oi, 'id', 'date', 'invoiceItemId', 'description', 'fee', 'quantity', 'rate'),
                ),
                budgetOtherItems: values.budgetOtherItems.map((boi) =>
                  _.pick(boi, 'id', 'invoiceItemId', 'description', 'fee'),
                ),
                monthlyBudgetOtherItems: values.monthlyBudgetOtherItems.map((mboi) =>
                  _.pick(mboi, 'id', 'invoiceItemId', 'description', 'fee'),
                ),
                invoiceMilestones: values.invoiceMilestones.map((bm) =>
                  _.pick(bm, 'id', 'date', 'invoiceItemId', 'details', 'fee', 'quantity', 'rate', 'type'),
                ),
                projectTagAssignments: values.tags.map((tag) => ({ projectTagId: tag.id })),
                revenueRecognitionEntries: values.revenueRecognitionEntries.map((rre) =>
                  _.pick(rre, 'id', 'date', 'notes', 'amount'),
                ),
                projectExpenses: values.projectExpenses.map((pe) =>
                  _.pick(pe, 'id', 'amount', 'date', 'expenseCategoryId', 'isBillable', 'notes', 'vendor'),
                ),
                budgetServicesGrossMargin: _.isNumber(values.budgetServicesGrossMargin)
                  ? values.budgetServicesGrossMargin / 100
                  : null,
              });

              const { data } = await api.www.workspaces(workspace.id).projects(project?.id).updateGraph(body);

              await onSaved(data);
              handlePathChange(data);
              form.save(formRef.current.status.action);

              if (formRef.current.status.action === 'close') {
                closeDrawer();
              } else {
                // TODO: resetting the project because, otherwise, the rows generated with an artificial key are not refreshed.
                // This should ideally rely on the "resetForm" function, but this implies that, instead o passing the "project"
                // down to the tabs. Otherwise, the tabs keep relying
                // Ideally, the commented code should be used (but it caused problems). Keep researching.
                // const newValues = mergeValues(defaultValues, data);
                // formik.resetForm({ values: newValues });
                actions.ready({ project: data });
              }
            } catch ({ message }) {
              drawerRef.current.scrollTo({ top: 0 });
              form.error({ message });
            }
          }

          return (
            <>
              <Formik
                innerRef={formRef}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}>
                {(formik) => {
                  const { values, setFieldValue, errors, submitForm, setStatus } = formik;

                  const submit = async (action) => {
                    // Submit all open inline forms
                    for await (const form of forms) {
                      await form.ref.current.submitForm();
                    }

                    // If at least one form is invalid, don't submit the drawer
                    for await (const form of forms) {
                      if (form.ref.current && !form.ref.current.isValid) return;
                    }

                    setStatus({ action });
                    submitForm();
                  };

                  return (
                    <Stack>
                      {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}

                      <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                        <Tab>
                          Overview
                          {tabErrors.overview.some((key) => !!errors[key]) && (
                            <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                          )}
                        </Tab>

                        <Tab>
                          <Inline>
                            Team
                            {tabErrors.team.some((key) => !!errors[key]) && (
                              <Tooltip message={formik.errors.members}>
                                <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                              </Tooltip>
                            )}
                          </Inline>
                        </Tab>

                        {values.useRoles && <Tab>Roles</Tab>}

                        <Tab>Accounting</Tab>

                        {(values.useBudget || values.useMonthlyBudget) && <Tab>Budget</Tab>}
                      </Tabs>
                      <Content>
                        {
                          [
                            <ProjectForm
                              key="overview"
                              drawerLoaded={drawerLoaded}
                              project={project}
                              projectModel={values}
                              formik={formik}
                              isSlackConnected={isSlackConnected}
                              QBOProjectsEnabled={QBOProjectsEnabled}
                            />,
                            <TeamTab
                              key="team"
                              project={project}
                              projectModel={values}
                              onChange={(value) => setFieldValue('members', value)}
                            />,
                            <RolesTab
                              key="roles"
                              project={project}
                              projectModel={values}
                              onUseRolesChange={(value) => setFieldValue('useRoles', value)}
                              onRolesChange={(value) => setFieldValue('roles', value)}
                              onRoleAssignmentsChange={(value) => setFieldValue('members', value)}
                            />,
                            <AccountingTab
                              key="accounting"
                              projectModel={values}
                              onInvoiceMilestonesChange={(value) => setFieldValue('invoiceMilestones', value)}
                              onOtherItemsChange={(value) => setFieldValue('otherItems', value)}
                              onProjectExpensesChange={(value) => setFieldValue('projectExpenses', value)}
                              onRevenueRecognitionLedgerChange={(value) =>
                                setFieldValue('revenueRecognitionEntries', value)
                              }
                            />,
                            <BudgetTab key="budget" formik={formik} project={project} />,
                          ][tabIndex]
                        }
                      </Content>

                      <Drawer.Actions>
                        {project.permissions.delete && (
                          <TooltipButton
                            component={DeleteButton}
                            disabled={project.hasInvoices || project.hasCreditNotes}
                            tooltipPlacement="right"
                            tooltip={
                              project.hasInvoices || project.hasCreditNotes
                                ? 'This project is associated with one or more invoices or credit notes and cannot be deleted.'
                                : undefined
                            }
                            onClick={handleDelete}>
                            Delete
                          </TooltipButton>
                        )}

                        <Buttons align="right">
                          <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                          <ActionButton
                            isOutline
                            isLoading={isSubmitting === 'stay'}
                            ok={saved === 'stay'}
                            onClick={() => submit('stay')}>
                            Save
                          </ActionButton>

                          <ActionButton
                            isLoading={isSubmitting === 'close'}
                            ok={saved === 'close'}
                            onClick={() => submit('close')}>
                            Save &amp; Close
                          </ActionButton>
                        </Buttons>
                      </Drawer.Actions>
                    </Stack>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </Drawer>
    </ProjectDrawerContext.Provider>
  );
}

export { ProjectDrawerContext };
export default ProjectDrawer;
