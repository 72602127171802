import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Currency,
  DateTime,
  Dropdown,
  ExportDialog,
  Icon,
  InfiniteScrollingObserver,
  Level,
  OpportunityLink,
  OpportunityStageFilter,
  Page,
  ProjectLink,
  SearchInput,
  SingleSelect,
  Spinner,
  Tooltip,
  OpportunityTypeSelect,
  MemberSelect,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import OpportunitiesSummaryWidget from './OpportunitiesSummaryWidget';
import OpportunityCloneForm from './OpportunityCloneForm';
import OpportunityDeleteConfirmation from './OpportunityDeleteConfirmation';
import OpportunityForm from './OpportunityForm';
import OpportunityToProjectForm from './OpportunityToProjectForm';
import StageProbabilityBar from './StageProbabilityBar';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 2rem;
  margin: 0 -2rem;
  margin-top: -2rem;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const OpportunityName = styled.div`
  display: flex;
  align-items: center;
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Small = styled.small`
  display: block;
`;

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

export default function OpportunitiesListPage({ renderFilters, mode = 'page', companyId }) {
  const integrations = useIntegrations();

  const documentTitle = useDocumentTitle();
  useEffect(() => {
    if (mode === 'page') documentTitle.set('Opportunities');
  }, [mode, documentTitle]);

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const { opportunityId } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();

  const [query, setQuery] = useState({
    data: null,
    params: {
      q: '',
      opportunityStages: [],
      opportunityStageStatusId: 'open',
      opportunityType: null,
      owner: null,
      page: 0,
      size: 25,
      sort: new QuerySort('convertedWeightedAmount', 'desc'),
    },
    action: 'load',
    status: 'loading',
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const [cloneTarget, setCloneTarget] = useState(null);
  const [opportunityToProject, setOpportunityToProject] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .opportunities()
        .get({
          ...params,
          opportunityStageId: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
          companyId,
          opportunityTypeId: params.opportunityType?.id,
          ownerId: params.owner?.id,
        });

      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params, companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    setQuery((state) => ({ ...state, status: 'load' }));
    setParams({ ...params, page: 0 });
    refreshSummary();
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, sort: new QuerySort(column, direction) });
  };

  const handleDelete = (opportunity) => {
    confirmation.prompt((resolve) => (
      <OpportunityDeleteConfirmation
        opportunity={opportunity}
        onClose={resolve}
        onDelete={() => {
          setQuery((state) => ({ ...state, status: 'load' }));
          setParams({ ...params, page: 0 });
          refreshSummary();
          resolve(true);
        }}
      />
    ));
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/edit/${id}`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/${id}/overview`);
  };

  const refreshSummary = () => {
    setRefreshKey((refreshKey) => refreshKey + 1);
  };

  async function handleCloned(opportunity) {
    try {
      toast.success('Opportunity cloned successfully.');
      history.push(`/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview/edit`);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/opportunities`);
    documentTitle.set('Opportunities');
  }

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .opportunities()
          .export(
            {
              ...params,
              opportunityStageId: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading' || !query.data) return <PageLoader />;

  const Container = mode === 'page' ? Page : React.Fragment;

  const salesforceEnabled = integrations?.salesforce;
  return (
    <Container>
      {mode === 'page' && (
        <SummarySection>
          <OpportunitiesSummaryWidget
            key={refreshKey}
            q={params.q}
            opportunityStageStatusId={params.opportunityStageStatusId}
            opportunityStages={params.opportunityStages}
          />
        </SummarySection>
      )}

      {renderFilters ? (
        renderFilters({ params, handleChange })
      ) : (
        <Page.Section>
          <Level padding="0.5rem 0 0 0">
            <Level.Item width="20rem">
              <SearchInput
                value={params.q}
                placeholder="All"
                materialPlaceholder="Search"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>

            <Level.Item width="20rem">
              <SingleSelect
                name="opportunityStageStatusId"
                placeholder="All"
                showEmptyOption
                materialPlaceholder="Stage Category"
                materialAlwaysVisible
                value={params.opportunityStageStatusId}
                onChange={handleChange}>
                <option value="open">Open</option>
                <option value="won">Won</option>
                <option value="lost">Lost</option>
              </SingleSelect>
            </Level.Item>

            <Level.Item>
              <OpportunityStageFilter
                name="opportunityStages"
                value={params.opportunityStages}
                opportunityStageStatusId={params.opportunityStageStatusId}
                onChange={handleChange}
              />
            </Level.Item>

            <Level.Item right narrow>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            {auth.pipeline.manage && (
              <Level.Item right narrow>
                <Tooltip message="Create Opportunity">
                  <AddLink to={`${url}/new`} className="button">
                    <Icon icon="plus" />
                  </AddLink>
                </Tooltip>
              </Level.Item>
            )}
          </Level>
          <Level padding="1rem 0 0 0">
            <Level.Item width="20rem" flex="1">
              <OpportunityTypeSelect
                name="opportunityType"
                value={params.opportunityType}
                placeholder="All"
                materialPlaceholder="Opportunity Type"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>
            <Level.Item width="20rem">
              <MemberSelect
                value={params.owner}
                name="owner"
                placeholder="All"
                materialPlaceholder="Relationship Owner"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>
            {salesforceEnabled && (
              <Level.Item width="20rem">
                <SingleSelect
                  name="salesforceSynced"
                  placeholder="All"
                  showEmptyOption
                  materialPlaceholder="Salesforce Synced"
                  materialAlwaysVisible
                  value={params.salesforceSynced}
                  onChange={handleChange}>
                  <option value="synced">Show Synced</option>
                  <option value="not-synced">Show Not Synced</option>
                </SingleSelect>
              </Level.Item>
            )}
          </Level>
        </Page.Section>
      )}

      <Page.Section>
        <Table.Status>
          {query.status !== 'ready' && <Spinner />}
          <Table.Total value={query.data.total} label="Opportunity" />
        </Table.Status>
        <Table>
          <Table.BoxHeader sticky>
            <Table.Column name="name" onSort={handleSort} sort={params.sort}>
              Opportunity Name
            </Table.Column>
            <Table.Column name="project" onSort={handleSort} sort={params.sort}>
              Project
            </Table.Column>
            <Table.Column name="stage" onSort={handleSort} sort={params.sort} width="8rem">
              Stage
            </Table.Column>
            <Table.Column name="convertedAmount" onSort={handleSort} sort={params.sort} width="10rem" align="right">
              Amount
            </Table.Column>
            <Table.Column
              name="convertedWeightedAmount"
              onSort={handleSort}
              sort={params.sort}
              width="11rem"
              align="right">
              <Tooltip message="Amount x Probability">Weighted Amount</Tooltip>
            </Table.Column>
            <Table.Column name="closeDate	" onSort={handleSort} sort={params.sort} width="8rem" align="right">
              Closing
            </Table.Column>
            {salesforceEnabled && (
              <Table.Column name="salesforceId" onSort={handleSort} sort={params.sort} width="3rem" align="right">
                <SalesforceIndicator />
              </Table.Column>
            )}
            <Table.BoxActionsColumn isVisible={mode === 'page'} />
          </Table.BoxHeader>

          <Table.Body fade={query.status === 'filtering'}>
            {query.data.results.map((opportunity) => {
              const {
                id,
                amount,
                opportunityStage,
                probability,
                closeDate,
                currency,
                convertedAmount,
                project,
                permissions,
                weightedAmount,
                convertedWeightedAmount,
                salesforceId,
              } = opportunity;

              async function handleRowClick(opportunity) {
                handleOverview(opportunity);
              }

              return (
                <Table.BoxRow key={id} onClick={() => handleRowClick(opportunity)}>
                  <Table.Cell>
                    <InfoContainer>
                      <OpportunityName>
                        <OpportunityLink opportunity={opportunity} onClick={(e) => e.stopPropagation()} />
                      </OpportunityName>
                      <small>{opportunity.company.name}</small>
                    </InfoContainer>
                  </Table.Cell>
                  <Table.Cell>
                    {project && (
                      <InfoContainer>
                        <ProjectLink project={project} onClick={(e) => e.stopPropagation()} />
                        <small>{project.client.name}</small>
                      </InfoContainer>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <StageContainer>
                      <span>{opportunityStage.name}</span>
                      <StageProbabilityBar probability={probability} stageStatus={opportunityStage.statusId} />
                    </StageContainer>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency
                        value={convertedAmount}
                        currency={workspace.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== workspace.currency && (
                        <Small>
                          <Currency
                            value={amount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <Tooltip message="Amount x Probability">
                      <p>
                        <Currency
                          value={convertedWeightedAmount}
                          currency={workspace.currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />
                        {currency !== workspace.currency && (
                          <Small>
                            <Currency
                              value={weightedAmount}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={2}
                            />
                          </Small>
                        )}
                      </p>
                    </Tooltip>
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={closeDate} />
                  </Table.Cell>
                  {salesforceEnabled && <Table.Cell>{salesforceId && <SalesforceIndicator />}</Table.Cell>}
                  <TableBoxRowActions isVisible={mode === 'page'}>
                    {auth.pipeline.manage ? (
                      <TableBoxRowActions.Edit onClick={() => handleEdit(opportunity)} />
                    ) : (
                      <TableBoxRowActions.View onClick={() => handleOverview(opportunity)} />
                    )}

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        return (
                          <>
                            <Dropdown.Item onClick={() => handleAction(handleOverview(opportunity))}>
                              View
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to edit this opportunity.' : undefined
                              }
                              onClick={() => handleAction(handleEdit(opportunity))}>
                              Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to delete this opportunity.' : undefined
                              }
                              onClick={() => handleAction(handleDelete(opportunity))}>
                              Delete
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to clone this opportunity.' : undefined
                              }
                              onClick={() => handleAction(setCloneTarget(opportunity))}>
                              Clone
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!!opportunity.project}
                              tooltip={(() => {
                                if (opportunity.project) return 'This opportunity is already linked to a project.';
                              })()}
                              onClick={() => handleAction(setOpportunityToProject(opportunity))}>
                              Create Project
                            </Dropdown.Item>
                          </>
                        );
                      }}
                    </TableBoxRowActions.Dropdown>
                  </TableBoxRowActions>
                </Table.BoxRow>
              );
            })}
          </Table.Body>
        </Table>

        {query.data.total > query.data.results.length && (
          <InfiniteScrollingObserver key={query.data.results.length} onIntersecting={loadMore} />
        )}
      </Page.Section>

      {opportunityId && <OpportunityForm onSaved={handleSaved} onClose={() => handleClose()} />}
      {cloneTarget && (
        <OpportunityCloneForm target={cloneTarget} onClose={() => setCloneTarget(null)} onSaved={handleCloned} />
      )}
      {opportunityToProject && (
        <OpportunityToProjectForm
          opportunity={opportunityToProject}
          onClose={() => setOpportunityToProject(false)}
          onSaved={(project) =>
            history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/overview/edit`)
          }
        />
      )}
    </Container>
  );
}
